import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './components/layout/layout.component';
import { RouterModule } from '@angular/router';
import { LayoutHeaderComponent } from './components/layout-header/layout-header.component';
import { LayoutSidebarComponent } from './components/layout-sidebar/layout-sidebar.component';
import { SpinnersComponent } from './components/spinners/spinners.component';
import { ButtonBackComponent } from './components/button-back/button-back.component';
import { CookiesModalComponent } from './components/cookies-modal/cookies-modal.component';
import { NotificationsModalComponent } from './components/notifications-modal/notification-modal.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LayoutComponent,
    LayoutHeaderComponent,
    LayoutSidebarComponent,
    SpinnersComponent,
    ButtonBackComponent,
    CookiesModalComponent,
    NotificationsModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    LayoutComponent,
    LayoutHeaderComponent,
    LayoutSidebarComponent,
    SpinnersComponent,
    ButtonBackComponent,
    CookiesModalComponent,
    NotificationsModalComponent,
    TranslateModule
  ]
})
export class SharedModule { }
