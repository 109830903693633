<div id="history" class="container">
  <div class="row">
    <div class="col-xl-9 col-12">
      <div
        class="row group-button mt-3 row-cols-2 row-cols-md-3 row-cols-lg-4 g-3"
      >
        <div class="col-auto history-group d-flex">
          <div class="gems"></div>
          <h2 class="text-history">Historial</h2>
        </div>
        <div class="col-auto meet-coin align-items-center order-md-1 order-4">
          <div class="rectangle-coin d-flex">
            <div class="coin img-store"></div>
            <p class="text-coin">${{ coins }} MeetCoin</p>
          </div>
        </div>
        <div class="col-auto align-items-center order-lg-2 order-1">
          <div (click)="goToGame()" class="rectangle d-flex">
            <div class="play img-store"></div>
            <p class="text-store-play">Jugar</p>
          </div>
        </div>
        <div class="col-auto align-items-center order-lg-3 order-2">
          <div (click)="goToStore()" class="rectangle d-flex">
            <div class="chest img-store"></div>
            <p class="text-store-play">Tienda</p>
          </div>
        </div>
      </div>
      <div class="row group-button row-cols-2 row-cols-md-3 row-cols-lg-3 g-1">
        <div class="col-auto d-flex">
          <h3 class="text-filtro">Filtrar por</h3>
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ dropdownTitle }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a class="dropdown-item" (click)="filterItems('all')"
                  >Todos</a
                >
              </li>
              <li>
                <a class="dropdown-item" (click)="filterItems('accepted')"
                  >Entregado</a
                >
              </li>
              <li>
                <a class="dropdown-item" (click)="filterItems('in progress')"
                  >En camino</a
                >
              </li>
              <li>
                <a class="dropdown-item" (click)="filterItems('denied')"
                  >Rechazado</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8 pagination align-items-center col-12">
          <div
            *ngIf="pagination"
            [ngClass]="{ disabled: page <= 1 }"
            (click)="changePageDown()"
            class="pagination-reverse"
          ></div>
          <p *ngIf="pagination" class="text-pagination">
            Página {{ page }}/{{ getPagesTotales() || 0 }}
          </p>
          <div
            *ngIf="pagination"
            (click)="changePageUp()"
            [ngClass]="{ disabled: page >= getPagesTotales() }"
            class="pagination-play"
          ></div>
        </div>
      </div>
      <div
        class="row group-title mt-3 row-cols-2 row-cols-md-3 row-cols-lg-4 g-3"
      >
        <div class="col-md-auto col-0 d-flex order-lg-1 order-4"></div>
        <div class="col-auto align-lg-items-center order-lg-2 order-1">
          <h1 class="title-card">Producto</h1>
        </div>
        <div class="col-auto align-items-center order-lg-3 order-2">
          <h1 class="title-card">MeetCoins</h1>
        </div>
        <div class="col-auto align-items-center order-lg-4 order-3">
          <h1 class="title-card">Status de entrega</h1>
        </div>
      </div>
      <div *ngIf="filteredItems.length" id="history-page" class="list-group container mb-5 mt-5">

        <a
          class="list-group-item list-group-item-action history-item row justify-content-around align-items-center"
          *ngFor="let item of filteredItems"
        >
          <div class="col-2 text-center">
            <img
              [src]="item.reward.imageUrl"
              class="img-product"
              alt="image"
            />
          </div>
          <div class="col-7">
            <div class="row">
              <div class="col-12 col-sm-6 text-center">
                <p class="mb-0 text-card">{{ item.reward.name }}</p>
              </div>
              <div class="col-12 col-sm-6 text-center">
                <p class="mb-0 text-card">
                  <img
                    src="/assets/img/store/coin.svg"
                    class="img-coin"
                    alt=""
                  />
                  ${{ item.reward.value }} MeetCoin
                </p>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-3 text-center">
            <p *ngIf="item.status === statusTypes.accepted" class="mb-0 text-card">{{ statusTypes.aceptado }}</p>
            <p *ngIf="item.status === statusTypes.pending" class="mb-0 text-card">{{ statusTypes.pendiente }}</p>
            <p *ngIf="item.status === statusTypes.denied" class="mb-0 text-card">{{ statusTypes.rechazado }}</p>
            <p *ngIf="item.status === statusTypes.progress" class="mb-0 text-card">{{ statusTypes.Ongoing }}</p>
          </div>
        </a>
      </div>
      <div *ngIf="!filteredItems.length" id="history-page" class="list-group container mb-5 mt-5">
        <p class="text-center text-white">No tienes ninguna operación en historial</p>
      </div>
    </div>
    <div
      class="col-xl-3 mt-xl-2 justify-contend-center align-items-center col-12"
    >
      <div class="advertising">Publicidad?</div>
    </div>
  </div>
</div>
