import { AfterViewChecked, Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { GameService } from 'src/app/services/game.service';
import { environment } from 'src/environments/environment';
import { StorageMap } from '@ngx-pwa/local-storage';
import { FOOTER } from 'src/utils/path';
import { NotificationService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
})
export class LayoutHeaderComponent implements OnInit, AfterViewInit{
  isLogin = false;
  lives = environment.initLives;
  coins = 0;
  wallet = 0;
  url = '';
  codeUser = '';
  mercadoWeb = environment.mercadoWeb;
  cookies: unknown;
  newNotify:boolean = false

  constructor(
    private _auth: AuthService,
    public _game: GameService,
    private _notify: NotificationService,
    private router: Router,
    private toastr: ToastrService,
    private storage: StorageMap,
  ) { }

  async ngOnInit() {

    this.storage.watch(`${environment.appName}-token`).subscribe(
      (res) => {
        this.isLogin = res === undefined ? false : true;
      }
    )
    this.isLogin = await this._auth.getLogin() && await this._auth.getToken();

    this._game.lives$.subscribe((livesNum) => {
      this.lives = livesNum;
    });

    const element = document.getElementById('header');
    if (element !== null) {
      element.scrollIntoView();
    }

    this.codeUser = await this._auth.getCode() || '202020';

    this._game.coins$.subscribe(async (coins) => {
      this.coins = coins;
    });


    this.url = `${environment.webGame}/register?code=${this.codeUser}`;

    this._game.wallet$.subscribe(async (wallet: number) => {
      this.wallet = wallet;
    });
    this.getWalletApi();

    try {
      const player = await this._game.getDataGame();

      // If player data exists, update component properties
      if (player !== undefined) { this._game.updateLives(player.lives, false); } else { this._game.initGame(); }
    } catch (error) {

    }

  }

  async ngAfterViewInit() {
    this.storage.watch('Aceept-cookies').subscribe(
      (res) => {
        this.cookies = res;
      }
    )
    await this._notify.updateNotifications();
    this._notify.notifications$.subscribe((notify) => {
      this.newNotify = notify.length !== 0
    });
  }

  async getWalletApi() {
    try {
      const response = await this._game.getWalletApi();
      this._game.updateWallet(response.coins)
    } catch (error) {
      console.error("error getting wallet")
    }
  }

  goToStore() {
    return this.router.navigate([FOOTER.STORE]);
  }
  goToRegister() {
    return this.router.navigate([FOOTER.REGISTER]);
  }
  goToLogin() {
    return this.router.navigate([FOOTER.LOGIN]);
  }
  async closeSesion() {

    this._game.clearData()
    this.toastr.success('Sesión cerrada correctamente', 'Éxito');
    return this.router.navigate([FOOTER.HOME])
  }

  copyToClipboard(text: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.toastr.success('Copiado en el portapapeles');
  }

  openNotifications() {
    this._game.notificationModal = true
  }
}
