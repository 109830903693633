import { Component } from '@angular/core';
import { FOOTER } from 'src/utils/path';

@Component({
  selector: 'app-button-back',
  templateUrl: './button-back.component.html',
  styleUrls: ['./button-back.component.scss']
})
export class ButtonBackComponent {
  home = FOOTER.HOME;

}
