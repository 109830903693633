<div id="notification-modal">
  <div class="modal-content">
    <span (click)="closeModal()" class="exit-button"></span>
    <div id="redeem-modal" class="card container">
      <div class="card-body">
        <section class="notify-container">
          <main *ngIf="lastNotifyIsRead"> 
            <h2 class="title-notify">No Hay notificaciones sin leer</h2>
          </main>
          <main *ngIf="!lastNotifyIsRead">
            <h2 class="title-notify">{{lastNotify.title}}</h2>
            <h4 class="content-notify">{{lastNotify.content}}</h4>
          </main>
        </section>
      </div>
    </div>
  </div>
</div>
