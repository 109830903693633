import { Injectable, OnInit, TemplateRef } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable, lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  /** NEW logig */

  //** sounds */

  spinSound = new Audio('/assets/audio/game.mp3');

  playSpinSound() {
    this.spinSound.currentTime = 0;
    // this.spinSound.play();
  }
  stopSpinSound() {
    this.spinSound.pause();
  }

  //RUN SPIN
  private runSpinSubject = new BehaviorSubject<boolean>(false);
  runSpin$: Observable<boolean> = this.runSpinSubject.asObservable();

  spin(spin: boolean) {
    const tryes = this.getTries();
    if (tryes == environment.maxTries) {
      const lives = this.getLives();
      if (lives > 0) {
        this.updateLives(lives - 1);
      } else {
        this.liveView = true;
      }
    }
    if(spin === true){ //TODO refactorisar
      this.updateTries(tryes - 1);
    }
    this.runSpinSubject.next(spin);
    this.saveData();
  }

  //CAN SPIN
  private canSpinSubject = new BehaviorSubject<boolean>(false);
  canSpin$: Observable<boolean> = this.canSpinSubject.asObservable();

  async updateCanSpin(value: boolean) {
    this.canSpinSubject.next(value);
  }

  //LIVES
  private livesSubject = new BehaviorSubject<number>(environment.initLives);
  lives$: Observable<number> = this.livesSubject.asObservable();

  private currentLiveSubject = new BehaviorSubject<number>(
    environment.initLives
  );
  currentLive$: Observable<number> = this.currentLiveSubject.asObservable();

  updateLives(value: number, saveData = true) {
    this.livesSubject.next(value);
    if (saveData) {
      this.saveData();
    }
  }

  //TRYES
  private triesSubject = new BehaviorSubject<number>(environment.initTries);
  tries$: Observable<number> = this.triesSubject.asObservable();

  updateTryes(value: number) {
    this.triesSubject.next(value);
    this.saveData();
  }

  getTries() {
    return this.triesSubject.value;
  }

  //WINNERS
  private winnersSubject = new BehaviorSubject<Array<number[]>>([]);
  winners$: Observable<Array<number[]>> = this.winnersSubject.asObservable();

  updateWinners(newWinners: number[]) {
    const winners = this.getWinners();
    this.winnersSubject.next([...winners, newWinners]);
    this.saveData();
  }

  deleteWinners() {
    this.winnersSubject.next([]);
    this.saveData();
  }

  getWinners() {
    return this.winnersSubject.value;
  }

  getNumbersWinnersFromSelected() {
    const countMap1 = this.getWinners()
      .map((winner) => {
        return parseInt(winner[0] + '' + winner[1]);
      })
      .reduce(
        (map: any, element) => ((map[element] = (map[element] || 0) + 1), map),
        {}
      );
    const countMap2 = this.getSelectedNumbers().reduce(
      (map: any, element) => ((map[element] = (map[element] || 0) + 1), map),
      {}
    );

    return Object.keys(countMap1).reduce(
      (count, element) =>
        count + Math.min(countMap1[element] || 0, countMap2[element] || 0),
      0
    );
  }

  // WALLET
  private walletSubject = new BehaviorSubject<number>(0);
  wallet$: Observable<number> = this.walletSubject.asObservable();

  async saveWallet(coins: any) {

    const token: string = await this._auth.getToken();
    const headers = new HttpHeaders().set('aat', token);
    const balance = {coins: coins};

    return await lastValueFrom(
      this._http.post(`${environment.api}/wallet`, balance, { headers }).pipe(
        map((response: any) => {
          this.updateWallet(response.coins);
          return response;
        })
      )
    );
  }

  async getWalletApi() {

    const token: string = await this._auth.getToken();
    const headers = new HttpHeaders().set('aat', token);

    return await lastValueFrom(
      this._http.get(`${environment.api}/wallet`, { headers }).pipe(
        map((response: any) => {
          this.updateWallet(response.coins);
          return response;
        })
      )
    );
  }

  updateWallet(value: number) {
    this.walletSubject.next(value);
  }

  getWallet() {
    return this.walletSubject.value;
  }
  //Selected Number

  private selectedNumbersSubject = new BehaviorSubject<number[]>([]);
  selectedNumbers$: Observable<number[]> =
    this.selectedNumbersSubject.asObservable();

  getSelectedNumbers(): number[] {
    return this.selectedNumbersSubject.value;
  }

  addSelectedNumber(number: number) {
    this.tapSound.play();
    this.tapSound.volume = 0.2;
    setTimeout(() => {
      this.tapSound.pause();
      this.tapSound.currentTime = 0;
    }, 450);
    const currentNumbers = this.selectedNumbersSubject.value;
    this.updateSelectedNumbers([...currentNumbers, number]);
  }

  updateSelectedNumbers(numbers: number[], saveData = true) {
    this.deleteWinners();
    this.selectedNumbersSubject.next(numbers);
    if (saveData) {
      this.saveData();
    }
  }

  removeSelectedNumber(number: number) {
    const currentNumbers = this.selectedNumbersSubject.value;
    const updatedNumbers = currentNumbers.filter((num) => num !== number);
    this.selectedNumbersSubject.next(updatedNumbers);
    this.saveData();
  }

  // COINS

  private coinsSubject = new BehaviorSubject<number>(0);
  coins$: Observable<number> = this.coinsSubject.asObservable();

  updateCoins(value: number) {
    this.coinsSubject.next(value);
    this.saveData();
  }

  getCoins(): number {
    return this.coinsSubject.value;
  }

  // INIT GAME

  get player() {
    const player = {
      lives: this.getLives(),
      tries: this.getTries(),
      numbers: this.getSelectedNumbers(),
      wins: this.getCoins(),
    };

    return player;
  }

  async initGame() {
    const player = {
      lives: 10,
      tries: 10,
      numbers: [],
      wins: 0,
    };;
    this.saveData(player);
  }

  //! rewards
  async exchangeReward(idReward: string, info: any) {
    const data = {...info, reward: idReward, date: new Date(Date.now())}
    console.log(data)

    const token: string = await this._auth.getToken();
    const headers = new HttpHeaders().set('aat', token);
    return await lastValueFrom(
      this._http.post(`${environment.api}/exchanges/create`, data, { headers }).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }


  //! LocalStorage
  async saveData(player: any = false) {

    player = player == false ? this.player : player;

    const token: string = await this._auth.getToken();
    const headers = new HttpHeaders().set('aat', token);
    this.storage.set('player', player).subscribe((player) => {});

    return await lastValueFrom(
      this._http.post(`${environment.api}/game`, player, { headers }).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async syncData() {
    let dbGameData = await this.getData()
    this.storage.set('player', dbGameData).subscribe((player) => {})
  }

  async getData() {
    const token: string = await this._auth.getToken();
    const headers = new HttpHeaders().set('aat', token);
    return await lastValueFrom(
      this._http.get(`${environment.api}/game`, { headers }).pipe(
        map((response: any) => {
          if (response !== null) {
            const { _id, userId, __v, ...rest } = response;
            return rest;
          } else {
            return this.player;
          }
        })
      )
    );
  }

  async getDataGame() {
    const token: string = await this._auth.getToken();
    const headers = new HttpHeaders().set('aat', token);
    return await lastValueFrom(
      this._http.get(`${environment.api}/game`, { headers }).pipe(
        map((response: any) => {
          if (response !== null) {
            const { _id, userId, __v, ...rest } = response;
            return rest;
          } else {
            return undefined;
          }
        })
      )
    );
  }

  clearData(){
    this.storage.delete('MEET-GAME-code').subscribe(() => {});
    this.storage.delete('MEET-GAME-isLogin').subscribe(() => {});
    this.storage.delete('MEET-GAME-token').subscribe(() => {});
    this.storage.delete('player').subscribe(() => {});
    if(!this.backgroundSoundGame.paused){this.backgroundSoundGame.pause()}
  }

  /** END new logic  */

  private winsSubject = new BehaviorSubject<number>(0);
  private winsNumbersSubject = new BehaviorSubject<number[]>([]);
  private rewardSubject = new BehaviorSubject<number>(0);
  private currentNumbersSubject = new BehaviorSubject<number[]>([]);
  private initGameSubjet = new BehaviorSubject<boolean>(false);
  playLose: boolean = false;
  winningSound = new Audio('/assets/audio/winner-coin.mp3');
  loseSound = new Audio('/assets/audio/lose.mp3');
  loseLifeSound = new Audio('/assets/audio/lose-life.mp3');
  //spinSound = new Audio('/assets/audio/spin.mp3');
  tapSound = new Audio('/assets/audio/tap.mp3');
  congratulationsYouWon = new Audio('/assets/audio/congratulations.mp3');
  backgroundSoundGame = new Audio('/assets/audio/font-game.mp3');
  winnerCoins = new Audio('/assets/audio/winner-coin.mp3');
  keepView: boolean = false;
  winView: boolean = false;
  liveView: boolean = false;
  disabledSpin: boolean = false;
  fullCells: boolean = false;
  modalStore: boolean = false;
  modalOrder: boolean = false;
  congratulations: boolean = false;
  stepOne = true;
  stepTwo = false;
  livingView = false;
  insufficientFunds = false;
  validationmodal = false
  notificationModal = false

  async getCookies(){
    return await lastValueFrom(
      this.storage.get('Aceept-cookies')
    );
  }
  // Observables para los datos
  wins$: Observable<number> = this.winsSubject.asObservable();
  winsNumbers$: Observable<number[]> = this.winsNumbersSubject.asObservable();
  reward$: Observable<number> = this.rewardSubject.asObservable();

  currentNumbers$: Observable<number[]> =
    this.currentNumbersSubject.asObservable();
  initGame$: Observable<boolean> = this.initGameSubjet.asObservable();

  //! Utils
  counter = 1;

  constructor(
    private storage: StorageMap,
    private _http: HttpClient,
    private _auth: AuthService
  ) {}

  backgroundSound(){
    this.backgroundSoundGame.loop = true;
    if(!this.backgroundSoundGame.paused){}else{this.backgroundSoundGame.play();}
    this.backgroundSoundGame.volume = 0.2;
  }

  viewModal() {
    let coins = this.getCoins();
    if (coins > 0) {
      setTimeout(() => {
        this.congratulations = true;
      }, 1390);
    }else{
      setTimeout(() => {
        this.livingView = true;
      }, 1390);
    }
  }

  async updateTries(tries: number, saveData = true) {
    if (tries <= 0) {
      setTimeout(() => this.updateSelectedNumbers([]), 1400 );
      setTimeout(() => this.deleteWinners(), 1430 );
      this.saveWallet(this.getCoins());

      const dbData = await this.getData();

      if ( ( (dbData.lives > 0 && dbData.tries > 0) && ( this.getLives() > 0) ) || ( this.getCoins() > 0 ) )  {
        this.viewModal();
      } else {
        this.liveView = true;
      }

      if(this.getLives() > 0) {
        this.triesSubject.next(environment.maxTries);
      } else {
        this.triesSubject.next(0);
      }


    } else {
      this.triesSubject.next(tries);
      this.getData();
    }
    if (saveData) {
      this.saveData();
    }
  }

  updateWins(wins: number, saveData = true) {
    this.winsSubject.next(wins);
    if (saveData) {
      this.saveData();
    }
  }

  updateWinsNumbers(winners: number[]) {
    if (this.getTries() > 0) {
      this.updateWinners(winners);
    }
    this.saveData();
  }

  updateCurrentNumbers(numbers: number[]) {
    this.currentNumbersSubject.next(numbers);
    this.saveData();
  }

  updateReward(reward: number) {
    this.rewardSubject.next(reward);
    this.saveData();
  }

  getReward(): number {
    return this.rewardSubject.value;
  }

  getCurrentNumbers(): number[] {
    return this.currentNumbersSubject.value;
  }

  getInitGame(): boolean {
    return this.initGameSubjet.value;
  }

  getLives(): number {
    return this.livesSubject.value;
  }

  clearSelectedNumbers() {
    this.selectedNumbersSubject.next([]);
  }

  spinnersSound() {
    // this.spinSound.play();
    setTimeout(() => {
      this.spinSound.pause();
      this.spinSound.currentTime = 0;
    }, 1900);
  }
  clickTap() {
    // this.tapSound.play();
    setTimeout(() => {
      this.tapSound.pause();
      this.tapSound.currentTime = 0;
    }, 450);
  }

  openModal(template: TemplateRef<any>) {
    if (this.getLives() === 0) {
      /*  this.modalRef = this.modalService.show(template) */
    }
  }

  calculateRewards() {
    const wins = this.winsSubject.value;

    if (wins === 9) {
      // this.congratulationsYouWon.play();
      this.updateReward(15);
    } else {
      this.updateReward(wins);
    }
  }

  tranforCurrentNumberInOnNumber() {
    const currentNumbers = this.currentNumbersSubject.value;
    const onNumber = parseInt(currentNumbers[0] + '' + currentNumbers[1] + '');
    //const onNumber = this.selectedNumbersSubject.value[0];
    return onNumber;
  }

  andYourWinningNumber(number: number): boolean {
    return this.selectedNumbersSubject.value.includes(number);
  }

  obtenerUbicacion(): Observable<any> {
    return this._http.get('https://ipapi.co/json/');
  }
}
