<header id="header">
  <div class="container">
    <div class="row items">
      <div class="col-auto">
        <a href={{mercadoWeb}} id="logo"></a>
      </div>
      <div class="col-auto buttons" *ngIf="!isLogin">
        <button [disabled]="cookies === undefined" (click)="goToRegister()" class="btn sing-up-button">Regístrarse</button>
        <button [disabled]="cookies === undefined" (click)="goToLogin()" class="btn sing-up-button">Iniciar Sesión</button>
      </div>

      <ng-container *ngIf="isLogin">
        <div class="col-auto icons">
          <div class="row align-items-center">
            <div class="col-auto md">
              <div class="action-header btn">
                <div class="icon-action-header">
                  <img src="/assets/img/header/coin-v2.svg" alt="" class="img-fluid" />
                </div>
                <div class="text-action-header">${{wallet}} MeetCoin</div>
              </div>
            </div>
            <div class="col-auto md">
              <div class="action-header btn">
                <div class="icon-action-header">
                  <img src="/assets/img/header/heart.png" alt="" class="img-fluid" />
                </div>
                <div class="text-action-header">{{lives}}</div>
              </div>
            </div>
            <div class="col-auto">
              <div (click)="goToStore()" class="action-header btn">
                <div class="icon-action-header">
                  <img src="/assets/img/header/chest.png" alt="" class="img-fluid" />
                </div>
                <div class="text-action-header">tienda</div>
              </div>
            </div>

            <div class="col-auto">
              <div class="avatar position-relative">
                <span *ngIf="newNotify" class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                  <span class="visually-hidden">New alerts</span>
                </span>
                <img src="/assets/img/header/avatar.png" class="img-fluid dropdown-toggle" data-bs-toggle="dropdown"
                  aria-expanded="false" alt="">
                <div class="dropdown">
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item text-copy" (click)="copyToClipboard(url)"> Código de referido <i class="fa-regular fa-copy"></i></a></li>
                    <li><a class="dropdown-item text-copy" (click)="openNotifications()">
                      Notificaciones
                    <i class="fa-solid fa-bell"></i>
                    <span *ngIf="newNotify" class="badge bg-secondary">+1</span>
                  </a></li>
                    <li><a class="dropdown-item text-close" (click)="closeSesion()">Cerrar sesión <i class="fa-solid fa-right-from-bracket"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</header>
<app-notifications-modal *ngIf="_game.notificationModal"></app-notifications-modal>