import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel/panel.component';
import { PanelRoutingModule } from './panel-routing.module';
import { SharedModule } from '../shared/shared.module';
import { HistoryComponent } from './history/history.component';

@NgModule({
  declarations: [PanelComponent, HistoryComponent],
  imports: [CommonModule, SharedModule, PanelRoutingModule],
})
export class PanelModule {}
