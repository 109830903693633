import { GameService } from './../../services/game.service';
import { TemplateRef, ViewChild, Component } from '@angular/core';
import { Product } from 'src/app/interfaces/products.interface';
import * as bootstrap from 'bootstrap';
//import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
  @ViewChild('testModal') testModal!: any;
  //modalRef?: BsModalRef;
  constructor(public gameService:GameService) {}

  products: Product[] = [
    {
      id: 1,
      productName: "Producto 1",
      amount: 10,
      image: "/assets/Rectangle.png"
    },
    {
      id: 2,
      productName: "Producto 2",
      amount: 5,
      image: "/assets/Rectangle.png"
    },
    {
      id: 3,
      productName: "Producto 3",
      amount: 8,
      image: "/assets/Rectangle.png"
    },
    {
      id: 4,
      productName: "Producto 4",
      amount: 12,
      image: "/assets/Rectangle.png"
    },
    {
      id: 5,
      productName: "Producto 5",
      amount: 7,
      image: "/assets/Rectangle.png"
    },
    {
      id: 6,
      productName: "Producto 6",
      amount: 15,
      image: "/assets/Rectangle.png"
    },
    {
      id: 7,
      productName: "Producto 7",
      amount: 9,
      image: "/assets/Rectangle.png"
    },
    {
      id: 8,
      productName: "Producto 8",
      amount: 6,
      image: "/assets/Rectangle.png"
    }
  ];

  selectedProductName = "";
  selectedProductImage = "";
  selectedAmount = 0;

  modalName: bootstrap.Modal | undefined

  openModal(template: TemplateRef<any>, product: Product) {
    this.selectedAmount = product.amount;
    this.selectedProductImage = product.image;
    this.selectedProductName = product.productName;

   /*  this.modalRef = this.modalService.show(template); */
  }
  closeModal() {
    /* this.modalRef?.hide(); */
  }
}
