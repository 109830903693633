<div id="main-layout">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <app-layout-header></app-layout-header>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
