export const environment = {
  production: false,
  api: 'https://api.dev.mercadomeet.com.co',
  mercadoWeb: 'https://dev.mercadomeet.com.co',
  mercadoWebAr: 'https://mercadomeet.com.ar',
  webGame: 'https://dev.meetcoin.cash',
  appName: 'MEET-GAME',
  maxNumberCanSelected: 9,
  initLives: 0,
  initTries: 0,
  maxTries: 10
};
