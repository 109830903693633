import { Injectable } from '@angular/core';
import { GameService } from 'src/app/services/game.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  stepOne = true;
  stepTwo = false;
  isLogin: boolean = false;

  constructor(public _game: GameService, private http:HttpClient,  private _auth: AuthService){}

  async getHistory(page:number = 1){
    const token: string = await this._auth.getToken();
    const headers = new HttpHeaders().set('aat', token);

    return await lastValueFrom(
        this.http.get(`${environment.api}/exchanges/user?page=${page}&limit=4`, { headers }).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

}
