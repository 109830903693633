import { Injectable } from '@angular/core';
import { GameService } from 'src/app/services/game.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, lastValueFrom, map } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  //* Observable for notifications
  private notificationSubject = new BehaviorSubject<Array<any>>([]);
  notifications$: Observable<Array<any>> = this.notificationSubject.asObservable();


  constructor( 
    private http:HttpClient,
    private _auth: AuthService)
    {}

  async getNotifications() {
    const token: string = await this._auth.getToken();
    const headers = new HttpHeaders().set('aat', token);

    return await lastValueFrom(
        this.http.get(`${environment.api}/notifications/user/game`, { headers }).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async readNotification(id: string){
    const token: string = await this._auth.getToken();
    const headers = new HttpHeaders().set('aat', token);

    return await lastValueFrom(
        this.http.get(`${environment.api}/notifications/read/${id}`, { headers }).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async updateNotifications() {
    this.notificationSubject.next([])
    const { ok, data } = await this.getNotifications()
    if (ok) {
      const { notifications, reads } = data

      const lastIsRead = reads.some((el:any) => el.notification === notifications[0]._id)
      if (!lastIsRead) {
        let notification = notifications[0]
        let currentValue = [notification]

        this.updateNotification(currentValue)

      }
    }
  }

  updateNotification(notification: any) {
    this.notificationSubject.next(notification);
  }
}
