import { ViewEncapsulation } from '@angular/compiler';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss'],
})
export class RedeemComponent {
  form!: FormGroup; // Define un FormGroup

  @Input()
  productName!: string;
  @Input()
  productImage!: string;
  @Input()
  amount!: number;

  // tabs,

  selectTab(tabId: number) {

  }
  showtab = false ;
  tabs = [
    { title: 'En camino', content: 'First Tab Content' },
    { title: 'Canjeados', content: 'Second Tab Content', active: true }
  ];
  canj = [
    {
      product: { name: "Reloj Smartwatch", image: "image"},
      status: "Despachado"
    },
    {
      product: { name: "Reloj Smartwatch", image: "image"},
      status: "En Camino a tu residencia"
    },
    {
      product: { name: "Reloj Smartwatch", image: "image"},
      status: "En espera"
    }
  ]
  // tabs

  @Output() closeTrigger: EventEmitter<any>;

  closeHelper() {
    this.closeTrigger.emit()
  }

  constructor(private formBuilder: FormBuilder) {
    // Inicializa el formulario con validaciones
    this.form = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      address: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.email]],
    });
    this.closeTrigger = new EventEmitter()
  }
  submitForm() {

    if (this.form.valid) {
      const formData = this.form.value;
      console.log(formData);
      this.showtab = true
    } else {

      console.error('El formulario no es válido. Por favor, corrige los campos marcados en rojo.');
    }
  }
}
