import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [

  {
    path: 'home',
    loadChildren: () =>
      import('./landing/landing.module').then(
        (mod) => mod.LandingModule
      ),
  },
    {
      path: 'game',
      loadChildren: () =>
        import('./home/home.module').then(
          (mod) => mod.HomeModule
        ),
    },
  {
    path: 'game',
    loadChildren: () =>
      import('./game/game.module').then(
        (mod) => mod.GameModule
      ),
  },
  {
    path: 'home-2',
    loadChildren: () =>
      import('./home/home.module').then(
        (mod) => mod.HomeModule
      ),
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () =>
      import('./panel/panel.module').then(
        (mod) => mod.PanelModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./account/auth/auth.module').then(
        (mod) => mod.AuthModule
      ),
  },
  {
    path: 'store',
    loadChildren: () =>
      import('./store/store.module').then(
        (mod) => mod.StoreModule
      ),
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
