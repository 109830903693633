import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLogin: boolean = false;

  constructor(private _storage: StorageMap, private _http: HttpClient) {}

  async setToken(token: string) {
    await lastValueFrom(
      this._storage.set(`${environment.appName}-token`, token)
    );
  }

  async getToken(): Promise<any> {
    try {
      return await lastValueFrom(
        this._storage.get(`${environment.appName}-token`)
      );
    } catch (error) {
      console.log("error getting token");
    }
  }

  async setCode(code: string) {
    await lastValueFrom(
      this._storage.set(`${environment.appName}-code`, code)
    );
  }

  async getCode(): Promise<any> {
    return await lastValueFrom(
      this._storage.get(`${environment.appName}-code`)
    );
  }

  async setLogin() {
    await lastValueFrom(
      this._storage.set(`${environment.appName}-isLogin`, true)
    );
  }


  async getLogin(): Promise<boolean> {
    const storedValue = await lastValueFrom(
      this._storage.get(`${environment.appName}-isLogin`)
    );

    return Boolean(storedValue);
  }

  async sendPhoneOtp(data: any): Promise<any> {
    return await lastValueFrom(
      this._http.post(`${environment.api}/validations/phone/send`, data).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async checkPhoneOtp(data: any): Promise<any> {
    return await lastValueFrom(
      this._http.post(`${environment.api}/validations/phone/check`, data).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async sendEmailOtp(data: any): Promise<any> {
    return await lastValueFrom(
      this._http.post(`${environment.api}/validations/email/send`, data).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async checkEmailOtp(data: any): Promise<any> {
    return await lastValueFrom(
      this._http.post(`${environment.api}/validations/email/check`, data).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async register(usuario: any) {
    return await new Promise((resolve, reject) => {
      const url = environment.api + '/create-user';
      this._http
        .post(url, usuario)
        .toPromise()
        .then((data: any) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async login(payload: any): Promise<any> {
    return await lastValueFrom(
      this._http.post(`${environment.api}/login`, payload).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  exitUser() {
    return new Promise((resolve, reject) => {
      let token = window.localStorage.getItem('mfToken');
      const url = environment.api + '/logout/' + token;
      this._http
        .get(url)
        .toPromise()
        .then((res: any) => {
          console.log(res);
          localStorage.clear();
          resolve(res);
        })
        .catch((err) => {
          localStorage.clear();
          console.log(err);
        });
    });
  }

  async validateUser(data: any): Promise<any> {
    const token: string = await this.getToken();
    const headers = new HttpHeaders().set('aat', token);
    return lastValueFrom(
      this._http.post(`${environment.api}/validations/user`, data, { headers }).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async uploadSelfie(data: any): Promise<any> {
    const token: string = await this.getToken();
    const headers = new HttpHeaders().set('aat', token);
    return lastValueFrom(
      this._http.post(`${environment.api}/validations/user-image`, data, { headers }).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async uploadDni(data: any): Promise<any> {
    const token: string = await this.getToken();
    const headers = new HttpHeaders().set('aat', token);
    return lastValueFrom(
      this._http.post(`${environment.api}/validations/user-dni`, data, { headers }).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }

  async checkValidation(): Promise<any> {
    const token: string = await this.getToken();
    const headers = new HttpHeaders().set('aat', token);
    return lastValueFrom(
      this._http.get(`${environment.api}/validations/user/check`, { headers }).pipe(
        map((response: any) => {
          return response;
        })
      )
    );
  }
}
