export enum FOOTER {
  RULES = '/game/rules',
  POLICY = '/game/policy',
  TERMS = '/game/terms',
  COOKIES = '/game/cookies',
  GAME = '/game/play',
  LOGIN = '/login',
  REGISTER = '/register',
  STORE = '/store',
  HISTORY = '/history',
  HOME = '/home',
  GAME_HOME = '/game',
  ABOUT = '/about'
}
