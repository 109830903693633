import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { History } from 'src/app/interfaces/history.interface';
import { Pagination } from 'src/app/interfaces/paginate.interface';
import { GameService } from 'src/app/services/game.service';
import { HistoryService } from 'src/app/services/history.service';
import { environment } from 'src/environments/environment';
import { FOOTER } from 'src/utils/path';

export const statusTypes = {
  all: 'Todos',
  accepted: 'accepted',
  denied: 'denied',
  pending: 'pending',
  progress: 'in progress',
  aceptado: 'Entregado',
  rechazado: 'Rechazado',
  Ongoing: 'En camino',
  pendiente: 'Pendiente',
};

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent {
  selectedStatus?: string;
  filteredItems: any[] = [];
  dropdownTitle: string = 'Todos';
  coins: number = 0;
  history: History[] = [] as History[];
  pagination: Pagination = {} as Pagination;
  filter = [];
  statusTypes = statusTypes;
  page = 1;
  url = '';

  constructor(
    private router: Router,
    public _game: GameService,
    public _history: HistoryService
  ) {}

  ngOnInit() {
    this._game.wallet$.subscribe(async (wallet: number) => {
      this.coins = wallet;
    });

    this.getWalletApi();
    this.getHistorys();
    this.url = `https://api.dev.mercadomeet.com.co/static/`;

    this._game.backgroundSound();
  }

  async getWalletApi() {
    const response = await this._game.getWalletApi();
    this._game.updateWallet(response.coins);
  }

  goToGame() {
    return this.router.navigate([FOOTER.GAME]);
  }

  goToStore() {
    return this.router.navigate([FOOTER.STORE]);
  }

  async getHistorys() {
    const response = await this._history.getHistory(this.page);

    this.pagination = {
      total: response.total,
      page: response.page,
      limit: 4,
    };

    this.filteredItems = response.exchanges;
    this.history = response.exchanges;
    this.filter = response.exchanges.status;
  }

  getPagesTotales() {
    const total = Math.ceil(this.pagination.total / this.pagination.limit);
    return total;
  }

  changePageUp() {
    if (this.page < this.getPagesTotales()) this.page += 1;
    this.getHistorys();
  }

  changePageDown() {
    if (this.page > 1) {
      this.page -= 1;
    }

    this.getHistorys();
  }

  translateStatus(status: string): string {
    if (status === 'all') return 'Todos';
    if (status === 'accepted') return 'Entregado';
    if (status === 'denied') return 'Rechazado';
    if (status === 'in progress') return 'En camino';
    if (status === 'pending') return 'Pendiente';
    return status;
  }

  filterItems(status: string) {
    this.selectedStatus = status;
    this.dropdownTitle = (statusTypes as any)[status];

    this.dropdownTitle = this.translateStatus(this.selectedStatus);

    if (status !== 'all') {
      this.filteredItems = this.history.filter(
        (item) => item.status === this.selectedStatus,
      );
      return;
    }

    this.filteredItems = this.history;
  }
}
