import { Component, OnInit } from '@angular/core';
import { GameService } from 'src/app/services/game.service';
import { NotificationService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationsModalComponent implements OnInit{
  lastNotify:any
  lastNotifyIsRead = true

  ngOnInit() {
    this._notify.notifications$.subscribe((notify) => {
      this.lastNotify = notify[0]
      this.lastNotifyIsRead = notify.length === 0
      if (!this.lastNotifyIsRead) {
        this._notify.readNotification(this.lastNotify._id)
      }
    })
  }

  constructor(
    public _game: GameService,
    public _notify: NotificationService
    ) {}

  closeModal(){
    this._notify.updateNotification([])
    this._game.notificationModal = false
  }

}
