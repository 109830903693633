import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { lastValueFrom } from 'rxjs';
import { GameService } from 'src/app/services/game.service';
import { FOOTER } from 'src/utils/path';

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.scss']
})
export class CookiesModalComponent {
  @ViewChild('myButton') myButton?: ElementRef;

  constructor(public _game: GameService, public _storage: StorageMap, public _route:Router){}

  async ngAfterViewInit() {
    const cookies = await this._game.getCookies();
    setTimeout(() => {
      if(cookies === undefined){
        this.myButton?.nativeElement.click()
       }
    }, 2000);
  }

  async acceptCookies(){
    await lastValueFrom(
      this._storage.set('Aceept-cookies', true)
    );
  }

  moreInfo(){
    this._route.navigate([FOOTER.COOKIES])
  }

}
