import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
  private _show = new BehaviorSubject(false);

  show$ = this._show.asObservable();

  show() {
    this._show.next(true);
  }

  hide() {
    this._show.next(false);
  }
}