<button
  #myButton
  type="button"
  class="btn btn-primary invisible"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal"
>
  Launch demo modal
</button>

<div
  class="modal fade"
  id="exampleModal"
  tabindex="1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="false"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Esta página web usa cookies
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        Este sitio web, meetcoin.cash, propiedad de MERCADO MEET S.A.S (NIT
        901.617.416), utiliza cookies para mejorar su experiencia de usuario y
        ofrecer contenidos personalizados. Si usted acepta el uso de las cookies
        podrá seguir navegando, en caso contrario no podrá hacer uso de la
        página.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Rechazo
        </button>
        <button
          type="button"
          (click)="acceptCookies()"
          data-bs-dismiss="modal"
          class="btn btn-light"
        >
          Acepto
        </button>
        <button
          type="button"
          (click)="moreInfo()"
          data-bs-dismiss="modal"
          class="btn btn-purple"
        >
          Más información
        </button>
      </div>
    </div>
  </div>
</div>
